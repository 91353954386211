/**
 *  Basic styling for the input elements.
 */
input
{
  // Use a transparent background.
  background: transparent;

  // Get rid of the border.
  border: none;

  // Get rid of the default outline.
  outline: none;

  // Make sure we inherit the local text styling.
  color: inherit;
  font-family: inherit;
  font-size: inherit;

  // Use an animation for hover and focus effects.
  transition-duration: var(--animation-speed-quick);
  transition-property: filter;

  // Subtly light up the input field when the cursor hovers it or when it is
  // selected to show that it is an interactive element.
  &:hover, &:focus { filter: brightness(125%); }
}