/**
 *  Styling for the emoji picker.
 */
.emojipicker
{
  // Use FlexBox to create a vertical layout.
  display: flex;
  flex-direction: column;

  // Keep some distance between categories.
  gap: 1em;

  // Keep some distance from the edges of the container.
  padding: 1em;

  // Let the user scroll through the emoji if necessary.
  overflow-y: scroll;

  // Target each category title.
  h3
  {
    // We don't need a larger font here.
    font-size: 1em;

    // We don't need extra margins here.
    margin: 0;

    // We don't need this font to stand out too much.
    color: hsla(
      var(--font-hue),
      var(--font-saturation),
      var(--font-lightness),
      var(--font-opacity)
    );
  }

  // Target all emoji containers.
  .emojis
  {
    // Make the emojis a little larger to make them easier to click.
    font-size: 1.5em;
  }

  // Hide hidden elements when requested.
  .hidden {
    visibility: hidden;
    max-height: 0;
  }
}