/**
 *  This file contains the definitions for global styling properties related to
 *  colors.
 *  For most colors, we simply CSS variables for all 4 different parts of HSLA
 *  to allow for fine-grained control over each of those parts separately
 *  throughout the app while we can still decide base levels centrally.
 */
:root
{
  // We set a base saturation level for all colours. We should use this base for
  // all colours and we should not vary saturation to ensure a
  // matching colour scheme across our application.
  --saturation-base: 80%;

  // We set a base lightness level for all colours. We want to use this to
  // have some control over the entire app's brightness level, but it is okay
  // and often useful to vary the lightness for individual elements.
  --lightness-base: 40%;
  --lightness-step: 10%;

  // We also set a base opacity level for all colours. We want to use this to
  // have some control over the entire app's opacity level, but we set this to 
  // be fully opaque by default which means no transparency at all.
  --opacity-base: 1;
  --opacity-step: 0.1;

  // Declare the base color hues.
  --hue-red: 0;
  --hue-orange: 30;
  --hue-green: 120;
  --hue-blue: 205;
  --hue-purple: 290;

  // For the basic background, we want a dark, neutral color.
  --background-hue: var(--hue-blue);
  --background-saturation: 0%;
  --background-lightness: 5%;
  --background-opacity: var(--opacity-base);

  // For the basic font, we want a neutral color that contrasts well against the
  // defeault background.
  --font-hue: 0;
  --font-saturation: 0%;
  --font-lightness: calc(var(--background-lightness) + 55%);
  --font-opacity: var(--opacity-base);
}