/**
 *  Styling for an chat-navigation-room in the list of chat rooms.
 */
.room
{
  // Get rid of the border.
  border: none;

  // Get rid of the default outline.
  outline: none;

  // Make sure we inherit the local text styling.
  font-family: inherit;
  font-size: inherit;

  // Use a slightly lighter background color.
  background-color: hsla(
    var(--background-hue),
    var(--background-saturation),
    calc(var(--background-lightness) + var(--lightness-step)),
    var(--background-opacity)
  );

  // Make the font color a little lighter for good contrast.
  color: hsla(
    var(--font-hue),
    var(--font-saturation),
    calc(var(--font-lightness)),
    var(--font-opacity)
  );

  // Take up all horizontal space.
  width: 100%;

  // Make sure nothing escapes this container.
  overflow: hidden;

  // Use some padding.
  padding: 1em 2em;

  // Include paddings with the calculations of the dimension of this element.
  box-sizing: border-box;

  // Show that the rooms are interactive.
  cursor: pointer;

  // Target both content rooms.
  .top, .bottom
  {
    // Use Flexbox to create a layout.
    display: flex;

    // Keep some space between the items.
    column-gap: 1ch;
    
    // Target the individual text containers for the name and the text of the
    // first message in the room.
    > span
    {
      // Make sure that we only use a single line to display text.
      white-space: nowrap;
  
      // Cut off text where we need to.
      overflow: hidden;
      
      // Show where we cut off text.
      text-overflow: ellipsis;
    }
  }

  // Make sure that we use all space in the top row of content.
  .top { justify-content: space-between; }

  // Align all text to the start in the bottom row of content.
  .bottom { justify-content: flex-start; }

  // Target only the room's name.
  .name
  {
    // Slightly increase the font size to make the name stand out.
    font-size: 1.25em;

    // Truncate the title if it gets too long.
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // Make sure that the time is always visible, no matter how long the room
  // name.
  .time { flex-shrink: 0; }

  // Target the sender name specifically.
  .sender
  {
    // Default to red to indicate that a custom hue is missing.
    --user-hue: 0;

    // Give each user its own color with all else to base values.
    color: hsla(
      var(--user-hue),
      var(--saturation-base),
      var(--lightness-base),
      var(--opacity-base)
    );
  }

  // Target the text preview.
  .text
  {
    // Make sure that we always shrink the text preview first if we run out of
    // space. We can guarantee this by setting the flex-shrink to a ludicrously 
    // large value.
    flex-shrink: 100; 

    // Make sure that this preview text can shrink all the way. It is even okay
    // if it disappears completely.
    min-width: 0;
  }

  // Target the room when the cursor hovers over it, or when it is selected.
  &:hover, &:focus
  {
    // Make it light up slightly.
    filter: brightness(125%);
  }

  // Target the currently opened chat room.
  &.selected
  {
    // Use a background-color similar to the chat itself to indicate that this
    // chat room belong to the currently open chat.
    background-color: hsla(
      var(--background-hue),
      var(--background-saturation),
      calc(var(--background-lightness) + var(--lightness-step) * 3),
      var(--background-opacity)
    );

    // The text needs to be lighter for sufficient contrast, but does not need
    // to match the increase of the background exactly because this text won't
    // need to draw the most attention..
    color: hsla(
      var(--font-hue),
      var(--font-saturation),
      calc(var(--font-lightness) + var(--lightness-step) * 3),
      var(--font-opacity)
    );
  }
}