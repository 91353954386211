/**
 *  Styling for the chatbox input.
 */
.input
{
  // Use a slightly lighter background color.
  background-color: hsla(
    var(--background-hue),
    var(--background-saturation),
    calc(var(--background-lightness) + var(--lightness-step)),
    var(--background-opacity)
  );

  // Match the font color for proper contrast.
  color: hsla(
    var(--font-hue),
    var(--font-saturation),
    calc(var(--font-lightness) + var(--lightness-step)),
    var(--font-opacity)
  );

  // Take up full width.
  width: 100%;

  // Make sure we include padding in the width.
  box-sizing: border-box;

  // Use FlexBox to create a vertical layout.
  display: flex;
  flex-direction: column;

  // Target the top row.
  .top
  {
    // Don't allow the emoji to take up the entire chatbox if that's not
    // necessary.
    max-height: 10em;

    // Use an animation for hiding and showing the emoji picker.
    transition-duration: var(--animation-speed-slow);
    transition-property: max-height, visibility;

    // Hide when empty or explicitly hidden.
    &:empty, &.hidden { 
      visibility: hidden;
      max-height: 0;
    }
  }

  // Target the bottom row.
  .bottom
  {
    z-index: 2;
    // Use a slightly lighter background color.
    background-color: hsla(
      var(--background-hue),
      var(--background-saturation),
      calc(var(--background-lightness) + var(--lightness-step)),
      var(--background-opacity)
    );

    // Provide some padding.
    padding: 1em;

    // Provide some distance between the text and the button.
    gap: 1em;

    // Use grid to create a layout.
    display: grid;

    // Give the buttons all the room they need, then use the rest for the input.
    grid-template-columns: max-content 1fr max-content;
  }

  // Target the input's placeholder text and the button when no text has been
  // entered.
  input::placeholder, input:placeholder-shown + button
  {
    // Make these elements stand out a little less.
    color: hsla(
      var(--font-hue),
      var(--font-saturation),
      var(--font-lightness),
      var(--font-opacity)
    );

    // Indicate that the button is currently not clickable.
    cursor: auto;

    // Remove on hover effects.
    &:hover, &:focus { filter: none; }
  }
}