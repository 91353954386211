/**
 *  Styling for the chatbox.
 */
.box
{
  // Use a lighter version of the default background color for some proper
  // contrast.
  background-color: hsla(
    var(--background-hue),
    var(--background-saturation),
    calc(var(--background-lightness) + var(--lightness-step) * 3),
    var(--background-opacity)
  );

  // Use Flexbox to create a layout.
  display: grid;

  // Create a vertical layout that gives the controls bar and the input all the
  // room they need, then use all that's left to display messages.
  grid-template-rows: max-content 1fr max-content;
  
  // Make the difference between components more defined.
  gap: 1px;

  // Prevent elements from leaving this container.
  overflow: hidden;
  
  // Anchor absolute child components to this container.
  position: relative;

  // Target the container for the messages.
  .messages
  {
    // Make sure we include the padding in the width calculation.
    box-sizing: border-box;

    // Use Flexbox to create a layout for the messages.
    display: flex;

    // Display messages from the bottom up.
    flex-direction: column-reverse;
    justify-content: flex-start;

    // Keep some space between messages.
    gap: 1em;

    // We should always have a single column.
    flex-wrap: nowrap;

    // Allow users to scroll up to see older messages when they're out of view.
    overflow-y: auto;

    // Align items to the left by default.
    align-items: flex-start;

    // Keep some distance from the edges of the container.
    padding: 1em;
  }

  // Hide the box when requested. We do want it to keep occupying its place in
  // the DOM, so we don't want to change the display here.
  &.hidden { visibility: hidden; }
}