/**
 *  Styling for a single chat message.
 */
.message
{
  // Make sure that we never use the full width so that we can always see if a
  // message is right or left aligned.
  max-width: 80%;

  // Use a slightly lighter background color.
  background-color: hsla(
    var(--background-hue),
    var(--background-saturation),
    calc(var(--background-lightness) + var(--lightness-step)),
    var(--background-opacity)
  );

  // Match the font color for proper contrast.
  color: hsla(
    var(--font-hue),
    var(--font-saturation),
    calc(var(--font-lightness) + var(--lightness-step) * 2),
    var(--font-opacity)
  );

  // Give each text message a more friendly text-bubble appearance with rounded
  // corners.
  border-radius: var(--border-radius);

  // Give the message some padding to give it some text balloon appearance.
  padding: .75em 1.5em;

  // Target messages from the user itself.
  &.self
  {
    // Align to the right.
    align-self: flex-end;
  }

  // Target the top row containing the name and timestamp.
  .top
  {
    // Use Flexbox to create a layout.
    display: flex;
    
    // Keep at least one character's width worth of space between the message
    // owner and the time.
    column-gap: 1ch;

    // Draw less attention to the message title elements by decreasing the
    // lightness.
    color: hsla(
      var(--font-hue),
      var(--font-saturation),
      var(--font-lightness),
      var(--font-opacity)
    );

    // Use all space that we have in the top row.
    justify-content: space-between;

    // Target all separate text elements in the message top.
    > span
    {
      // Make sure that we only use a single line to display text.
      white-space: nowrap;
  
      // Cut off text where we need to.
      overflow: hidden;
  
      // Show where we cut off text.
      text-overflow: ellipsis;

      // Make sure that the time is always visible, no matter how long the sender
      // name.
      &.time { flex-shrink: 0; }

      // Target the sender name specifically.
      &.sender
      {
        // Default to red to indicate that a custom hue is missing.
        --user-hue: 0;

        // Give each user its own color with all else to base values.
        color: hsla(
          var(--user-hue),
          var(--saturation-base),
          var(--lightness-base),
          var(--opacity-base)
        );
      }
    }
  }

  // Target the bottom row containing the message.
  .bottom
  {
    // Make sure that text cannot break out of the container.
    overflow-wrap: break-word; 
  }
}