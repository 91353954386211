/**
 *  Styling for the app layout.
 */
.chat
{
  // Use all available height.
  height: 100%;

  // Make sure we don't use the padding in the height calculation.
  box-sizing: border-box;

  // Target the container that houses the main content.
  > main
  {
    // Use all available height.
    height: 100%;

    // Use the default background color.
    background-color: hsla(
      var(--background-hue),
      var(--background-saturation),
      var(--background-lightness),
      var(--background-opacity)
    );

    // Use grid to create a layout for the different parts of the app.
    display: grid;
  
    // Show only the navigation by default.
    grid-template-columns: 100% 0%;
  
    // Hide all overflow.
    overflow: hidden;

    // Center the main content on the page horizontally.
    margin: 0 auto;

    // Anchor absolute child components to this container.
    position: relative;

    // Show the chatbox instead when the showRoom class is added.
    &.showRoom { grid-template-columns: 0% 100%; }
  }

  // Target the chat on larger screens.
  @media (min-width: 40em)
  {
    // Make sure we keep some minimal distance from the edge of the window.
    padding: 2em;

    // Target the container that houses the main content.
    > main, > main.showRoom {
  
      // Give the navigation all the room it needs, then use the rest for the
      // chatbox.
      grid-template-columns: min(25em, 35%) 3fr;

      // Make the difference between components more defined.
      gap: 1px;

      // Make sure our interface does not grow ridiculously large.
      max-width: 80em; 
      
      // Give the interface some rounded corners for a friendlier look.
      border-radius: var(--border-radius);
    }
  }
}