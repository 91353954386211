/**
 *  File that imports all basic styles.
 */

// Import all global styling properties.
@forward 'properties';

// Import all global element styling.
@forward 'elements';

// Target all top level containers. Note thata  container with the ID of 'root'
// is added by React to wrap the entire application.
html, body, #root
{  
  // Make sure we take up all available space.
  position: absolute;
  inset: 0;
}