/**
 *  Basic styling for the button elements.
 */
button
{
  // Use a dark background color.
  background-color: transparent;

  // Get rid of the border.
  border: none;

  // We don't need padding by default.
  padding: 0;

  // Get rid of the default outline.
  outline: none;

  // Make sure we inherit the local text styling.
  color: inherit;
  font-family: inherit;
  font-size: inherit;

  // Make sure that it is clear that this is clickable.
  cursor: pointer;

  // Use an animation for hover and focus effects.
  transition-duration: var(--animation-speed-quick);
  transition-property: filter;

  // Subtly light up the button when the cursor hovers it or when it is selected
  // to show that it is an interactive element.
  &:hover, &:focus { filter: brightness(125%); }
}